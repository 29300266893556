export default function(language: string): any {
  /**
   * This workaround ensures we're loading no.json for Norwegian
   * when cldr-core tries to load nb.json. The current version of CLDR
   * only contains nb.json for Norwegian. This can be removed once we upgrade to CLDR >=v39.
   * Which contains no, nb and nn language packs.
   *
   * I18NPLAT-1983 to remove this.
   * Copy of https://github.com/sqsp/i18n-frontend-tools/blob/master/libraries/i18n-ui/src/functions/cldr/framework.js#L11-L20
   */
  const langTag = language === 'nb' ? 'no' : language;
  const langPack: any = window?.SQUARESPACE_I18N?.cldrResourcePacks?.[langTag];

  if (langPack) {
    return langPack;
  }

  try {
    return require(`@sqs/i18n-ui/packs/${langTag}.json`);
  } catch (error) {
    const isI18nContextDefined = !!window.SQUARESPACE_I18N;
    const loadedCldrPacks = isI18nContextDefined &&
      Object.keys(window.SQUARESPACE_I18N.cldrResourcePacks).join();
    /**
     * This can happen under the following conditions:
     *  1. SqsI18nContextPlugin is used to removed @sqs/i18n-ui/packs/ from the "current" entrypoint
     *  2. The HTML page where the "current" entrypoint is being render is missing {rollup-cldr-resource-pack}
     *
     * How to fix:
     *  - add {rollup-cldr-resource-pack} to the pack
     */

    throw new Error(`Unable to load i18n cldr pack. Language=(${langTag}) ` +
      `SqspI18nContextDefined=(${isI18nContextDefined}) ` +
      `LoadedCLdrPacks=(${loadedCldrPacks}) ` +
      error.message
    );
  }
}
