"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.Flag */

/* Java class defined in repository squarespace-v6 */
var Flag;

(function (Flag) {
  Flag["CHECKOUT_V2_ENABLED"] = "checkout_v2_enabled";
  Flag["NOT_SUBJECT_TO_INITIAL_US_TAX_ROLLOUT"] = "us_tax_grandfathered";
  Flag["US_TAX_BETA"] = "us_tax_beta";
  Flag["USER_ACCOUNTS_ENABLED"] = "user_accounts_enabled";
  Flag["API_KEY_MANAGER_ENABLED"] = "api_key_manager_enabled";
  Flag["READ_USERS_FROM_SERVICE"] = "SITE_USER_SERVICE-read-users-from-service";
  Flag["GET_OR_CREATE_USERS_FROM_SERVICE"] = "SITE_USER_SERVICE-get-or-create-users-from-service";
  Flag["GET_OR_CREATE_USERS_FROM_SERVICE_SKIP_FALLBACK"] = "SITE_USER_SERVICE-get-or-create-users-from-service-skip-fallback";
  Flag["READ_USERS_FROM_SERVICE_BY_EMAIL"] = "SITE_USER_SERVICE-read-users-from-service-by-email";
  Flag["UPDATE_USERS_LAST_SHIPPING_ADDRESS_WITH_SERVICE"] = "SITE_USER_SERVICE-update-users-last-shipping-address";
  Flag["UPDATE_USERS_LAST_BILLING_ADDRESS_WITH_SERVICE"] = "SITE_USER_SERVICE-update-users-last-billing-address";
  Flag["READ_USERS_FROM_SERVICE_BY_ACCOUNTID"] = "SITE_USER_SERVICE-read-users-from-service-by-accountid";
  Flag["UPDATE_USERS_NAME_WITH_SERVICE"] = "SITE_USER_SERVICE-update-users-name";
  Flag["READ_USERS_FROM_SERVICE_BY_WEBSITE_ID"] = "SITE_USER_SERVICE-read-users-from-service-by-websiteId";
  Flag["ADD_ADDRESSES_WITH_SERVICE"] = "SITE_USER_SERVICE-add-address-book-entry-from-service";
  Flag["UPDATE_ADDRESS_WITH_SERVICE"] = "SITE_USER_SERVICE-update-address-book-entry-from-service";
  Flag["UPDATE_ACCOUNTID_WITH_SERVICE"] = "SITE_USER_SERVICE-update-accountid-from-service";
  Flag["DELETE_ADDRESSES_WITH_SERVICE"] = "SITE_USER_SERVICE-delete-address-book-entry-from-service";
  Flag["DELETE_NOTE_WITH_SERVICE"] = "SITE_USER_SERVICE-delete-note-from-service";
  Flag["ADD_NOTE_WITH_SERVICE"] = "SITE_USER_SERVICE-add-note-from-service";
  Flag["READ_NOTES_BY_SITE_USER_WITH_SERVICE"] = "SITE_USER_SERVICE-read-note-by-site-user-from-service";
  Flag["CLEAR_SHOPPINGCART_WITH_SERVICE"] = "SITE_USER_SERVICE-clear-shopping-cart-from-service";
  Flag["CLEAR_SHOPPINGCARTS_WITH_SERVICE"] = "SITE_USER_SERVICE-clear-shopping-carts-from-service";
  Flag["SET_SHOPPINGCARTID_WITH_SERVICE"] = "SITE_USER_SERVICE-set-shopping-cart-id-from-service";
  Flag["ADD_PAYMENT_CARD_WITH_SERVICE"] = "SITE_USER_SERVICE-add-payment-card-from-service";
  Flag["SEARCH_SITE_USERS_WITH_SERVICE"] = "SITE_USER_SERVICE-search-site-users-from-service";
  Flag["UPDATE_TRANSACTION_SUMMARY_WITH_SERVICE"] = "SITE_USER_SERVICE-update-transaction-summaries-from-service";
  Flag["UPDATE_PAYMENT_CARD_WITH_SERVICE"] = "SITE_USER_SERVICE-update-payment-card-from-service";
  Flag["DELETE_PAYMENT_CARD_WITH_SERVICE"] = "SITE_USER_SERVICE-delete-payment-card-from-service";
  Flag["DELETE_TAGS_BY_WEBSITE_WITH_SERVICE"] = "SITE_USER_SERVICE-delete-tags-by-website-from-service";
  Flag["GET_OR_CREATE_TAGS_WITH_SERVICE"] = "SITE_USER_SERVICE-get-or-create-tags-from-service";
  Flag["GET_ACTIVE_TAGS_WITH_SERVICE"] = "SITE_USER_SERVICE-get-active-tags-from-service";
  Flag["GET_ACTIVE_TAGS_BY_WEBSITE_WITH_SERVICE"] = "SITE_USER_SERVICE-get-active-tags-by-website-from-service";
  Flag["UPDATE_TAGS_WITH_SERVICE"] = "SITE_USER_SERVICE-update-tags-from-service";
  Flag["RENAME_TAG_LABEL_WITH_SERVICE"] = "SITE_USER_SERVICE-rename-tag-label-from-service";
  Flag["SUS_UPDATE_GROUPS_WITH_TAGS"] = "SITE_USER_SERVICE-update-groups-with-tags";
  Flag["SUS_READ_GROUPS_WITH_TAGS"] = "SITE_USER_SERVICE-read-groups-with-tags";
  Flag["REFUND_ORDER_FROM_SERVICE"] = "ORDERS-SERVICE-refund-order-with-service";
  Flag["REFUND_ORDER_USING_WORKFLOW"] = "ORDERS-SERVICE-refund-order-using-workflow";
  Flag["CANCEL_ORDER_FROM_SERVICE"] = "ORDERS-SERVICE-cancel-order-with-service";
  Flag["CANCEL_ORDER_USING_WORKFLOW"] = "ORDERS-SERVICE-cancel-order-using-workflow";
  Flag["DELETE_ORDERS_WITH_SERVICE"] = "ORDERS-SERVICE-delete-orders-with-service";
  Flag["EXPORT_ORDERS_FROM_SERVICE"] = "ORDERS_SERVICE-export-orders-from-service";
  Flag["CHECK_DIGITAL_GOODS_ACCESS_WITH_SERVICE"] = "ORDERS-SERVICE-check-digital-good-access-with-service";
  Flag["RESET_DIGITAL_GOODS_ACCESSS_WITH_SERVICE"] = "ORDERS-SERVICE-reset-digital-goods-access-with-service";
  Flag["WRITE_SNAPSHOT_ORDERS_TO_SERVICE"] = "ORDERS-SERVICE-write-snapshot-orders-to-service";
  Flag["SUBMIT_ORDER_THROUGH_SERVICE"] = "ORDERS-SERVICE-submit-order-through-service";
  Flag["SUBMIT_GIFTCARD_ORDER_THROUGH_SERVICE"] = "ORDER_SERVICE-submit-giftcard-order-through-service";
  Flag["SUBMIT_SUBSCRIPTION_ORDER_THROUGH_SERVICE"] = "ORDER_SERVICE-submit-subscription-order-through-service";
  Flag["SUBMIT_REOCCURRING_SUBSCRIPTION_ORDER_THROUGH_SERVICE"] = "ORDER_SERVICE-submit-reoccurring-subscription-order-through-service";
  Flag["SUBMIT_ORDER_WITH_GIFT_CARD_PAYMENTS_THROUGH_SERVICE"] = "ORDER_SERVICE-submit-order-with-gift-card-payments-through-service";
  Flag["FILTER_FEATURES"] = "filter_features";
  Flag["STANDARDIZED_ANALYTICS"] = "standardizedAnalytics";
  Flag["IMAGE_LOADER_MODULE"] = "image_loader_module";
  Flag["RESPECT_LOCALE_FOR_WEBSITE_RENDERING"] = "respect_locale_for_website_rendering";
  Flag["ANALYTICS_ANOMALY_BETA_CONFIG"] = "ANALYTICS_anomaly_beta_config";
  Flag["ANALYTICS_ANOMALY_BETA"] = "ANALYTICS_anomaly_beta_enabled";
  Flag["ANALYTICS_ANOMALY_PUSH_NOTIFICATION_BETA"] = "ANALYTICS_anomaly_push_notification_beta";
  Flag["INTERNATIONALIZATION_PRIVATE_BETA"] = "internationalization_private_beta";
  Flag["INTERNATIONALIZATION_MEMBERS_BETA"] = "internationalization_member_beta";
  Flag["TLDS_BETA"] = "tlds_beta";
  Flag["DG_DOWNLOADS_FROM_FASTLY"] = "dg_downloads_from_fastly";
  Flag["ZAPIER_FORM_INTEGRATION"] = "zapier_form-integration";
  Flag["COMMERCE_PRODUCT_API_V2"] = "commerce_product_api_v2";
  Flag["PENDO_INTEGRATION"] = "pendo-integration";
  Flag["COMMERCE_SUBSCRIPTION_PRODUCTS"] = "commerce_subscription_products";
  Flag["COMMERCE_SUBSCRIPTION_DISCOUNTS"] = "commerce_subscription_discounts";
  Flag["PRODUCT_SERVICE_ENABLED"] = "product_service_enabled";
  Flag["NEWSLETTERS"] = "newsletters";
  Flag["CAMPAIGNS_EARLY_ACCESS"] = "campaigns_early_access";
  Flag["REACT_FIRST"] = "react_first";
  Flag["IMAGE_BLOCK_ANIMATIONS"] = "image_block_animations";
  Flag["QUOTE_BLOCK_ANIMATIONS"] = "quote_block_animations";
  Flag["VIDEO_BLOCK_ANIMATIONS"] = "video_block_animations";
  Flag["GALLERY_BLOCK_ANIMATIONS"] = "gallery_block_animations";
  Flag["SOCIAL_MEDIA_GALLERY_ASYNC"] = "social_media_gallery_async";
  Flag["DEMO_CONTENT_ABTEST"] = "demo_content_abtest";
  Flag["IN_APP_HELP"] = "in_app_help";
  Flag["IN_APP_HELP_CHAT"] = "in_app_help_chat";
  Flag["IN_APP_HELP_EMAIL"] = "in_app_help_email";
  Flag["MEMBER_SESSION"] = "member_session";
  Flag["USE_SAILTHRU_ONLY"] = "use_sailthru_only";
  Flag["NEGOTIATED_RATES"] = "negotiated_rates";
  Flag["PRODUCT_REVIEWS"] = "product_reviews";
  Flag["SEO_PLUS"] = "seo_plus";
  Flag["CAMPAIGNS_NO_IFRAME"] = "campaigns_no_iframe";
  Flag["CAMPAIGNS_WEBSITE_STYLES"] = "campaigns_website_styles";
  Flag["MENU_HEADER_REFACTOR"] = "menu_header_refactor";
  Flag["CONNECTED_ACCOUNTS_REFACTOR"] = "connected_accounts_refactor";
  Flag["ENHANCED_SEARCH"] = "enhanced_search";
  Flag["RELATED_PRODUCTS"] = "related_products";
  Flag["VIEW_CART_LINK"] = "view_cart_link";
  Flag["INVENTORY_PANEL_V2"] = "inventory_panel_p2";
  Flag["CLDR_NUMBER_FORMATTER"] = "cldr_number_formatter";
  Flag["V8_SAVE_AS_VARIANT"] = "v8_save_as_variant";
  Flag["V8_SERVER_SIDE_JSONT"] = "v8_server_side_jsont";
  Flag["V8_ADVANCED_EDITING"] = "v8_advanced_editing";
  Flag["V8_UNRELEASED_FEATURES"] = "v8_unreleased_features";
  Flag["V8_SITE_DIRECTORY"] = "v8_site_directory";
  Flag["V8_ALL_SECTIONS_SELECTABLE"] = "v8_all_sections_selectable";
  Flag["V8_ALL_REGIONS_EDITABLE"] = "v8_all_regions_editable";
  Flag["V8_SHOW_DEFAULT_VARIANTS"] = "v8_show_default_variants";
  Flag["V8_VARIANT_DESIGNER"] = "v8_variant_designer";
  Flag["V8_STARTER_PAGE_INSTALL"] = "v8_starter_page_install";
  Flag["DOMAINS_IN_REACT"] = "domains_in_react";
  Flag["FACEBOOK_PIXEL"] = "facebook_pixel";
  Flag["INDEX_PRODUCTS_THROUGH_SERVICE"] = "index_products_through_service";
  Flag["STYLE_EDITOR_SHOW_VARIANTS"] = "style_editor_show_variants";
  Flag["TWEAKS_V2_EXPERIMENTAL_FEATURES"] = "tweak_v2_experimental_features";
  Flag["SHOW_DELETE_USER_BUTTON"] = "delete_user_button";
  Flag["ROSETTA_BLOCK_EDITORS"] = "rosetta_block_editors";
  Flag["PANEL_REORG"] = "panel_reorg";
  Flag["MEMBER_AREA_BLOCK"] = "member_area_block";
  Flag["MEMBER_AREAS"] = "member_areas";
  Flag["GENERIC_IFRAME_LOADER_FOR_CAMPAIGNS"] = "generic_iframe_loader_for_campaigns";
  Flag["DONATIONS_CUSTOMER_ACCOUNTS"] = "donations_customer_accounts";
  Flag["PAYWALL_ON_MEMBER_AREAS"] = "paywall_on_member_areas";
  Flag["UNSPLASH_IMAGE_PICKER"] = "unsplash_image_picker";
  Flag["STYLE_EDITOR_SHOW_VARIANTS_CONTROL"] = "style_editor_show_variants_control";
  Flag["STYLE_EDITOR_SHOW_VARIANTS_V2"] = "style_editor_show_variants_v2";
  Flag["STYLE_EDITOR_SHOW_VARIANTS_V2_CONTROL"] = "style_editor_show_variants_v2_control";
  Flag["IN_APP_HELP_CHAT_CONTROL"] = "in_app_help_chat_control";
  Flag["CAMPAIGNS_EXPERIMENTAL_COMPOSER_UI"] = "campaigns_experimental_composer_ui";
  Flag["CAMPAIGNS_AUTOMATIONS"] = "campaigns_automations";
  Flag["PANEL_REORG_CIRCLE_TOGGLE"] = "panel_reorg_circle_toggle";
  Flag["COMMERCE_PAYMENT_REQUEST_API"] = "commerce_payment_request_api";
  Flag["COMMERCE_ACCOUNTING_V2"] = "commerce_accounting_v2";
  Flag["PAGE_SEO_TAB_SETTINGS"] = "page_seo_tab_settings";
  Flag["YUI_FIRST"] = "yui_first";
  Flag["COMMERCE_PAYPAL_FORCE_MANUAL_ONBOARDING"] = "commerce_paypal_force_manual_onboarding";
  Flag["COMMERCE_PAYPAL_SANDBOX"] = "commerce-paypal-sandbox";
  Flag["POINT_OF_SALE"] = "point_of_sale";
  Flag["FACEBOOK_DYNAMIC_ADS"] = "facebook_dynamic_ads";
  Flag["PRODUCT_RETRIEVER_V2_ENABLED"] = "product-retriever-v2-enabled";
  Flag["SITE_VISIBILITY"] = "site_visibility";
  Flag["SITE_VISIBILITY_CONTROL"] = "site_visibility_control";
  Flag["DOMAIN_REGISTRATION_VIA_REGISTRAR_SERVICE"] = "domain_registration_via_registrar_service";
  Flag["IMAGE_EDITOR"] = "image_editor";
  Flag["VANILLA_FORM_HANDLER"] = "vanilla_form_handler";
  Flag["INVENTORY_API"] = "inventory_api";
  Flag["IMAGE_EDITOR_EN"] = "image_editor_en";
  Flag["PGS_STRIPE_RETRIEVE_PAYMENT"] = "pgs_stripe_retrieve_payment";
  Flag["PGS_PAYPAL_RETRIEVE_PAYMENT"] = "pgs_paypal_retrieve_payment";
  Flag["PGS_STRIPE_REFUND_PAYMENT"] = "pgs_stripe_refund";
  Flag["PGS_PAYPAL_REFUND_PAYMENT"] = "pgs_paypal_refund";
  Flag["PGS_STRIPE_CHARGE_PAYMENT"] = "pgs_stripe_charge";
  Flag["PGS_PAYPAL_CHARGE_PAYMENT"] = "pgs_paypal_charge";
  Flag["PGS_SETTINGS_READ"] = "pgs_settings_read";
  Flag["PGS_SETTINGS_UPDATE"] = "pgs_settings_update";
  Flag["PGS_CASH_ALL"] = "pgs_cash_all";
  Flag["MARKETING_PANEL_EOQ4"] = "marketing_panel_eoq4";
  Flag["DROP_TRAILING_SLASHES"] = "drop_trailing_slashes";
  Flag["DOMAIN_LOCKING_VIA_REGISTRAR_SERVICE"] = "domain_locking_via_registrar_service";
  Flag["IN_APP_CHAT_DURING_CHECKOUT"] = "in_app_chat_during_checkout";
  Flag["IN_APP_CHAT_DURING_CHECKOUT_CONTROL"] = "in_app_chat_during_checkout_control";
  Flag["IMAGE_EDITOR_EXPERIMENTAL_FEATURES"] = "image_editor_experimental_features";
  Flag["DOMAIN_DELETION_VIA_REGISTRAR_SERVICE"] = "domain_deletion_via_registrar_service";
  Flag["DOMAIN_ADMINISTRATION_VIA_REGISTRAR_SERVICE"] = "domain_info_via_registrar_service";
  Flag["CONTENT_BROWSER"] = "content_browser";
  Flag["DISABLE_CONTENT_BROWSER"] = "disable_content_browser";
  Flag["ROSETTA_ACCESSIBILITY_COMPLIANCE_TOOL"] = "rosetta_accessibility_compliance_tool";
  Flag["ROSETTA_THEME_EDITOR"] = "rosetta_theme_editor";
  Flag["CONTENT_ITEM_SETTINGS"] = "content_item_settings";
  Flag["BLOG_EVENT_ITEM_SETTINGS"] = "blog_event_item_settings";
  Flag["BLOG_PANEL"] = "blog_panel";
  Flag["EVENTS_PANEL"] = "events_panel";
  Flag["EVENTS_PANEL_70"] = "events_panel_70";
  Flag["BLOG_EVENTS_PANELS"] = "blog_events_panels";
  Flag["REACT_PAGES_PANEL"] = "react_pages_panel";
  Flag["BLOG_METADATA_IMPROVEMENTS_SEPT_2021"] = "blog_metadata_improvements_sept_2021";
  Flag["INLINE_EVENT_EDITING_SEVEN_ONE"] = "inline_event_editing_seven_one";
  Flag["DEMO_CONTENT_IMPROVEMENT"] = "demo_content_improvement";
  Flag["NEW_STACKED_INDEX"] = "new_stacked_index";
  Flag["THEMES"] = "themes";
  Flag["JACQUARD_CUSTOM_SWATCHES"] = "jacquard_custom_swatches";
  Flag["COMMERCE_INVENTORY_SETTINGS_PANEL"] = "commerce_inventory_settings_panel";
  Flag["DOMAIN_RENEWAL_VIA_REGISTRAR_SERVICE"] = "domain_renewal_via_registrar_service";
  Flag["ADD_NEW_PAGE_ALT"] = "add_new_page_alt";
  Flag["ADD_NEW_PAGE_ALT_TWO"] = "add_new_page_alt_two";
  Flag["SOCIAL_LINKS"] = "social_links";
  Flag["SITE_HEADER_FOOTER"] = "site_header_footer";
  Flag["IMAGE_PICKER"] = "image_picker";
  Flag["CAMPAIGNS_API_MOCK"] = "campaigns_api_mock";
  Flag["ADD_NEW_PAGE_CONTROL"] = "add_page_new_control";
  Flag["DISALBE_SIGNUP_JS"] = "disable_signup";
  Flag["COLLECTION_TYPENAME_SWITCHING"] = "collection_typename_switching";
  Flag["COLLECTION_ORCHESTRATOR_ENDPOINTS"] = "collection_orchestrator_endpoints";
  Flag["OVERRIDE_BLOCK_STYLES"] = "override_block_styles";
  Flag["GOOGLE_APPS_NEW_OFFERING_2019"] = "google_apps_new_offering_2019";
  Flag["CAMPAIGNS_BLOG_TO_EMAIL_BUTTON"] = "campaigns_blog_to_email_button";
  Flag["CAMPAIGNS_CONFIRMATION_EMAIL"] = "campaigns_confirmation_email";
  Flag["LOCAL_LISTINGS"] = "local_listings";
  Flag["CAMPAIGNS_BILLING"] = "campaigns_billing";
  Flag["PRODUCT_VARIANT_IMAGES"] = "product-variant-images";
  Flag["REVEAL_USER_INFORMATION"] = "reveal_user_info";
  Flag["ONBOARDING_WIZARD"] = "onboarding_wizard";
  Flag["ONBOARDING_WIZARD_SEVEN_ONE"] = "onboarding_wizard_seven_one";
  Flag["TEMPLATE_SWITCHER_REFACTOR"] = "template_switcher_refactor";
  Flag["SIGN_UP_PILL_REFACTOR"] = "sign-up-pill-refactor";
  Flag["SECTION_ADMIN"] = "section_admin";
  Flag["HIDE_LOW_PERFORMING_SECTIONS"] = "hide_low_performing_sections";
  Flag["SEVEN_ONE_JSON_SCHEMA_SECTION_EDITOR"] = "seven_one_json_schema_section_editor";
  Flag["SEVEN_ONE_PORTFOLIO_SLIDE_LAYOUTS"] = "seven_one_portfolio_slide_layouts";
  Flag["SEVEN_ONE_IMAGE_OVERLAY_OPACITY"] = "seven_one_image_overlay_opacity";
  Flag["SEVEN_ONE_LIST_SECTION_CONTENT_TOGGLE_UI"] = "seven_one_list_section_content_toggle_ui";
  Flag["SEVEN_ONE_ANCHOR_LINKS"] = "seven_one_anchor_links";
  Flag["ACCORDION_BLOCK"] = "accordion_block";
  Flag["FLUID_IMAGE_BLOCK"] = "fluid_image_block";
  Flag["IMAGE_SHAPES"] = "image_shapes";
  Flag["SEVEN_ONE_IMAGE_EFFECTS"] = "seven_one_image_effects";
  Flag["SEVEN_ONE_LIST_SECTION_CARD_THEMES"] = "seven_one_list_section_card_themes";
  Flag["SEVEN_ONE_LIST_SECTION"] = "seven_one_list_section";
  Flag["GALLERY_CAPTIONS_71"] = "gallery_captions_71";
  Flag["GALLERY_LIGHTBOX_71"] = "gallery_lightbox_71";
  Flag["GALLERY_VIDEOS_71"] = "gallery_videos_71";
  Flag["ONBOARDING_CARDS_71"] = "onboarding_cards_71";
  Flag["COMMERCE_RESTOCK_NOTIFICATIONS"] = "commerce_restock_notifications";
  Flag["RSS_EXPANDED_FEED"] = "RSS_EXPANDED_FEED";
  Flag["COMMERCE_APPS_PANEL_V2"] = "commerce_apps_panel_v2";
  Flag["POS_ENABLED"] = "pos_enabled";
  Flag["NESTED_CATEGORIES"] = "nested_categories";
  Flag["NESTED_CATEGORIES_MIGRATION_ENABLED"] = "nested_categories_migration_enabled";
  Flag["NESTED_CATEGORIES_MIGRATION_LOCK"] = "nested_categories_migration_lock";
  Flag["LESSONS_NESTED_CATEGORIES"] = "lessons_nested_categories";
  Flag["COMMERCE_RELATED_PRODUCTS"] = "commerce_related_products";
  Flag["AUREUS_CHECKOUT_STYLES"] = "aureus_checkout_styles";
  Flag["PRODUCT_API"] = "product_api";
  Flag["TRANSACTION_API"] = "transaction_api";
  Flag["LOCAL_LISTINGS_ALPHA"] = "local_listings_alpha";
  Flag["COMMERCE_CUSTOM_SOLD_OUT_TEXT"] = "commerce_custom_sold_out_text";
  Flag["SCRIPTS_DEFER"] = "scripts_defer";
  Flag["ALLOW_VIP_SIZE_UPLOADS"] = "allow_vip_size_uploads";
  Flag["GLOBAL_ANIMATIONS"] = "global_animations";
  Flag["HEADER_COLOR"] = "header_color";
  Flag["COMMERCE_PRODUCT_COMPOSER"] = "commerce_product_composer";
  Flag["COMMERCE_TRIAL_SURVEY"] = "commerce_trial_survey";
  Flag["COMMERCE_BULK_MANAGER"] = "commerce_bulk_manager";
  Flag["ACUITY_ROUTE"] = "acuity_route";
  Flag["ININE_ITEM_EDITING_FOR_SEVEN_ONE"] = "inline_item_editing_for_seven_one";
  Flag["SHOW_SQUARE_CONNECTION_ON_WEB"] = "show_square_connection_on_web";
  Flag["SEVEN_ONE_MANAGE_ITEMS_BUTTON"] = "seven_one_manage_items_button";
  Flag["COMMERCE_STRIPE_PAYMENT_INTENTS_API"] = "commerce_stripe_payment_intents_api";
  Flag["SEVEN_ONE_ADD_PAGE"] = "seven_one_add_page";
  Flag["SITE_CLONING"] = "dashboard_site_cloning_enabled";
  Flag["WEBSITE_SETTINGS_PATCH_API"] = "website_settings_patch_api";
  Flag["ENABLE_IPAD_OS_DD"] = "enable_ipad_os_dd";
  Flag["SEVEN_ONE_NO_REFRESH_ON_MANAGE_ITEMS"] = "seven_one_no_refresh_on_manage_items";
  Flag["MOBILE_NO_IFRAME"] = "mobile_no_iframe";
  Flag["SOLD_OUT_TEXT_IN_VARIANTS_DROPDOWN"] = "sold_out_in_variants_dropdown";
  Flag["DOMAINS_ROSETTA_PARKING_PAGE_ONBOARDING"] = "domains_rosetta_parking_page_onboarding";
  Flag["NEWSLETTER_BLOCK_CAPTCHA"] = "newsletter_block_captcha";
  Flag["NEW_BILLING_SYSTEM"] = "new_billing_system";
  Flag["SEVEN_ONE_BLOCK_ANNOTATIONS"] = "seven_one_block_annotations";
  Flag["SEVEN_ONE_BLOCK_OUTLINES"] = "seven_one_block_outlines";
  Flag["COMMERCE_NEW_MERCH_SETTINGS"] = "commerce_new_merch_settings";
  Flag["SEVEN_ONE_IMPORT_EXPORT"] = "seven_one_import_export";
  Flag["SUBMIT_DONATION_THROUGH_ORDERS_SERVICE"] = "submit_donation_through_orders_service";
  Flag["SEVEN_ONE_THEME_MAPPER_V2"] = "seven_one_theme_mapper_2";
  Flag["COMMERCE_DISABLE_WAITLIST"] = "commerce_disable_waitlist";
  Flag["GALLERY_SETTINGS_71"] = "gallery_settings_71";
  Flag["DONATIONS_BLOCK_2"] = "donations_block_2.0";
  Flag["EXTENSIONS_PANEL"] = "extensions_panel";
  Flag["SUBMIT_ORDER_THROUGH_WORKFLOW_WITH_SAVING_PAYMENT"] = "ORDERS-SERVICE-submit-order-with-saving-payment-through-service";
  Flag["MOBILE_INFO_BAR_V2"] = "mobile_info_bar_v2";
  Flag["GIFTCARDS_EXPORT"] = "giftcards_export";
  Flag["IMAGE_LAZY_LOADING_71"] = "image_lazy_loading_71";
  Flag["SEVEN_ONE_ABTEST_NEW_FEATEURES_NOVEMBER_2019"] = "seven_one_abtest_nov_2019";
  Flag["COMMERCE_ONBOARDING_WIZARD"] = "commerce_onboarding_wizard";
  Flag["USE_COMPUTED_MASTER_VOLUME_TWEAKS"] = "use_computed_master_volume_tweaks";
  Flag["SEVEN_ONE_PORTFOLIO_HOVER_LAYOUTS"] = "seven_one_portfolio_hover_layouts";
  Flag["COMMERCE_MINIMUM_ORDER_AMOUNT"] = "commerce_minimum_order_amount";
  Flag["SEVEN_ONE_THEME_MAPPER_V3"] = "seven_one_theme_mapper_v3";
  Flag["SUBSCRIPTION_MEMBER_AREAS"] = "subscription_member_areas";
  Flag["SEVEN_ONE_CATALOG_PERFORMANCE_IMPROVEMENTS"] = "seven_one_catalog_performance_improvements";
  Flag["SEVEN_ONE_ABTEST_2020_FIRST_SET"] = "seven_one_abtest_2020_set1";
  Flag["SEVEN_ONE_DYNAMIC_TEXT_SIZING"] = "seven_one_dynamic_text_sizing";
  Flag["SEVEN_ONE_HYPHENATION"] = "seven_one_hyphenation";
  Flag["COMMERCE_ONBOARDING_WIZARD_PHASE_TWO"] = "commerce_onboarding_wizard_phase_two";
  Flag["CRM_PRODUCT"] = "crm_product";
  Flag["CRM_CAMPAIGNS_SENDING"] = "crm_campaigns_sending";
  Flag["CRM_REDIRECT_FROM_CUSTOMERS"] = "crm_redirect_from_customers";
  Flag["CRM_ADVANCED_FILTERING"] = "crm_advanced_filtering";
  Flag["CRM_DELETE_CUSTOMER_ACCOUNTS"] = "crm_delete_customer_accounts";
  Flag["CAMPAIGNS_STANDALONE"] = "campaigns_standalone";
  Flag["DOMAINS_ALLOW_ASYNC_TRANSFER"] = "domains_allow_async_transfer";
  Flag["DOMAINS_TRANSFER_FLOW_IMPROVEMENTS"] = "domains_transfer_flow_improvements";
  Flag["DOMAINS_ALLOW_ASYNC_GSUITE"] = "domains_allow_async_gsuite";
  Flag["COMMERCE_TAX_PANEL_V2"] = "commerce_tax_panel_v2";
  Flag["DOMAINS_USE_NEW_DOMAIN_CONNECT_STRATEGY"] = "domains_use_new_domain_connect_strategy";
  Flag["MEMBER_AREAS_FEATURE_GATING"] = "member_areas_feature_gating";
  Flag["SEVEN_ONE_DEFAULT_THEME_REMOVAL_ENABLED_GROUP"] = "seven_one_default_theme_removal_enabled";
  Flag["SEVEN_ONE_DEFAULT_THEME_REMOVAL_DISABLED_GROUP"] = "seven_one_default_theme_removal_disabled";
  Flag["COMMERCE_INSTAGRAM_PRODUCT_CHECKOUT_LINKS"] = "commerce_instagram_product_checkout_links";
  Flag["CAMPAIGNS_SINGLE_OPT_IN"] = "campaigns_single_opt_in";
  Flag["LIST_SENT_TO_GROUPS"] = "list_sent_to_groups";
  Flag["SEVEN_ONE_FONTS_PANEL_TARGETING"] = "seven_one_fonts_panel_targeting";
  Flag["SEVEN_ONE_FONTS_PANEL_V2"] = "seven_one_fonts_panel_v2";
  Flag["SEVEN_ONE_FONTS_PANEL_TARGETING_MODAL"] = "seven_one_fonts_panel_targeting_modal";
  Flag["CUSTOMER_NOTIFICATIONS_PANEL_V2"] = "customer_notifications_panel_v2";
  Flag["TRANSACTIONAL_EMAIL_PIPELINE_V2_ENABLED"] = "transactional_email_pipeline_v2_enabled";
  Flag["SEVEN_TO_SEVEN_ONE_MIGRATION"] = "seven_to_seven_one_migration";
  Flag["SEVEN_ONE_USER_SURVEY_ON_EDITING"] = "seven_one_user_survey_on_editing";
  Flag["SEVEN_ONE_USER_SURVEY_ON_STYLING"] = "seven_one_user_survey_on_styling";
  Flag["SEVEN_ONE_USER_SURVEY_ON_MOBILE"] = "seven_one_user_survey_on_mobile";
  Flag["SEVEN_ONE_NEW_PALETTES_APRIL"] = "seven_one_new_palettes_april";
  Flag["SEVEN_ONE_CONTEXTUAL_STYLE_EDITING_V1"] = "seven_one_contextual_style_editing_v1";
  Flag["SEVEN_ONE_COLORS_PANEL_IMPROVEMENTS"] = "seven_one_colors_panel_improvements";
  Flag["COMMERCE_PRODUCT_COMPOSER_OPT_IN"] = "commerce_product_composer_opt_in";
  Flag["COMMERCE_CURRENCY_BRL"] = "commerce_currency_brl";
  Flag["COMMERCE_CURRENCY_ARS"] = "commerce_currency_ars";
  Flag["COMMERCE_CURRENCY_COP"] = "commerce_currency_cop";
  Flag["COMMERCE_CURRENCY_INR"] = "commerce_currency_inr";
  Flag["COMMERCE_CURRENCY_IDR"] = "commerce_currency_idr";
  Flag["COMMERCE_CURRENCY_JPY"] = "commerce_currency_jpy";
  Flag["COMMERCE_CURRENCY_ZAR"] = "commerce_currency_zar";
  Flag["COMMERCE_SUBSCRIPTION_ORDER_DELAY"] = "commerce_subscription_order_delay";
  Flag["COMMERCE_ENABLE_SQUARE_SANDBOX"] = "commerce_enable_square_sandbox";
  Flag["COMMERCE_ACTIVATION_EXPERIMENT_PAYMENTS_FAQ"] = "commerce_activation_experiment_payments_faq";
  Flag["COMMERCE_ACTIVATION_EXPERIMENT_ADD_PAYMENT_PROCESSOR_CARD"] = "commerce_activation_experiment_add_payment_processor_card";
  Flag["SITE_DUPLICATION_COPY_ASSETS"] = "site_duplication_copy_assets";
  Flag["COMMERCE_PRODUCT_COMPOSER_ALL_TYPES"] = "commerce_product_composer_all_types";
  Flag["MOBILE_PREVIEW_PAGE_EDITING"] = "mobile_preview_page_editing";
  Flag["VARIANT_PICKER_LAYOUT_TWEAK"] = "variant_picker_layout_tweak";
  Flag["DISABLE_SYSTEM_SCRIPTS"] = "disable_system_scripts";
  Flag["DISABLE_SITE_CSS"] = "disable_site_css";
  Flag["DOMAINS_TRANSFER_FLOW_HIDE_DNS"] = "domains_transfer_flow_hide_dns";
  Flag["SUMMARY_BLOCK_SUPPORT_FOR_PRODUCTS_NESTED_CATEGORIES"] = "summary_block_support_for_products_nested_categories";
  Flag["BYPASS_PAGE_LIMIT_SITE_DUPLICATION"] = "bypass_page_limit_site_duplication";
  Flag["COMMERCE_SETUP_WIZARD"] = "commerce_setup_wizard";
  Flag["TRUST_ARC_ON_CONFIG"] = "trust_arc_on_config";
  Flag["SEVEN_ONE_BLOG_MOBILE_STYLES"] = "seven-one-blog-mobile-styles";
  Flag["VIEWER_ROLE_CONTRIBUTOR_INVITES"] = "viewer-role-contributor-invites";
  Flag["SEVEN_ONE_NEW_GALLERY_COMPONENT"] = "seven-one-new-gallery-component";
  Flag["SEVEN_ONE_GALLERY_MOBILE_STYLES"] = "seven-one-gallery-mobile-styles";
  Flag["DOMAINS_TRANSFER_FLOW_HIDE_PREFACE"] = "domains_transfer_flow_hide_preface";
  Flag["SEVEN_ONE_HEADER_EDITOR_WITH_MOBILE_OPTIONS"] = "seven_one_header_editor_update";
  Flag["COMMERCE_PRODUCT_COMPOSER_AB_TEST_FEATURES"] = "commerce_product_composer_ab_test_features";
  Flag["SEVEN_ONE_AESTHETICS_PATCH_API"] = "seven_one_aesthetics_patch_api";
  Flag["SEVEN_ONE_TWEAKS_PATCH_API"] = "seven_one_tweaks_patch_api";
  Flag["COMMERCE_FACEBOOK_PANEL"] = "commerce_facebook_panel";
  Flag["MEMBER_AREAS_BETA_V2"] = "member_areas_beta_v2";
  Flag["MEMBER_AREAS_FEATURE"] = "member_areas_feature";
  Flag["MEMBER_AREAS_GA"] = "member_areas_ga";
  Flag["MEMBER_AREAS_DISCOUNTS"] = "member_areas_discounts";
  Flag["MEMBER_AREAS_PMF_SURVEY"] = "member_areas_pmf_survey";
  Flag["SEVEN_ONE_SUMMARY_BLOCK_MOBILE_STYLES"] = "seven-one-summary-block-mobile-styles";
  Flag["SEVEN_ONE_CONTENT_PREVIEW_SECTION_API"] = "seven-one-content-preview-section-api";
  Flag["SEVEN_ONE_SECTION_DUPLICATION"] = "seven-one-section-duplication";
  Flag["CAMPAIGNS_EDITOR_ROLE"] = "campaigns-editor-role";
  Flag["ENABLE_I18N_LANGUAGE"] = "i18n-language";
  Flag["NEMO"] = "nemo";
  Flag["APPSHELL"] = "appshell";
  Flag["SEVEN_ONE_MENU_OVERLAY_THEME_SWITCHER"] = "seven-one-menu-overlay-theme-switcher";
  Flag["SEVEN_ONE_MOBILE_HEADER_FONT_TWEAKS"] = "seven-one-mobile-header-font-tweaks";
  Flag["SEVEN_ONE_MOBILE_NAV_FONT_TWEAK"] = "seven-one-mobile-nav-font-tweak";
  Flag["SEVEN_ONE_QUICK_VIEW_LIGHTBOX_COLORS"] = "seven-one-quick-view-lightbox-colors";
  Flag["SEVEN_ONE_HAMBURGER_ON_DESKTOP"] = "seven-one-hamburger-on-desktop";
  Flag["SEVEN_ONE_HEADER_PROMOTED_ELEMENTS"] = "seven-one-header-promoted-elements";
  Flag["COMMERCE_RECAPTCHA_ENTERPRISE"] = "commerce-recaptcha-enterprise";
  Flag["SEVEN_ONE_FRONTEND_RENDER_HEADER"] = "seven_one_frontend_render_header";
  Flag["SEVEN_ONE_FRONTEND_RENDER_HEADER_RELEASE"] = "seven_one_frontend_render_header_release";
  Flag["SEVEN_ONE_FRONTEND_RENDER_PAGE_SECTION"] = "seven_one_frontend_render_page_section";
  Flag["SEVEN_ONE_FRONTEND_RENDER_GALLERY_SECTION"] = "seven_one_frontend_render_gallery_section";
  Flag["SEVEN_ONE_MAIN_CONTENT_PREVIEW_API"] = "seven-one-main-content-preview-api";
  Flag["SEVEN_ONE_FONTS_SUBPANEL_AUTOHIGHLIGHT"] = "seven_one_fonts_subpanel_autohighlight";
  Flag["CAMPAIGNS_NEW_SENDER_PROFILE_PAGE"] = "campaigns_new_sender_profile_page";
  Flag["DOMAINS_UNIVERSAL_SEARCH"] = "domains_universal_search";
  Flag["COMMERCE_PRODUCT_COMPOSER_AB_TEST"] = "commerce_product_composer_ab_test";
  Flag["ANIMATIONS_AUGUST_2020_NEW_PRESET"] = "animations_august_2020_new_preset";
  Flag["COMMERCE_PRODUCT_COMPOSER_FORCE_ENABLED"] = "commerce_product_composer_force_enabled";
  Flag["STANDALONE_PERMISSIONS_PANEL"] = "standalone_permissions_panel";
  Flag["COMMERCE_CATEGORY_ID_DISCOUNTS_ENABLED"] = "commerce_category_id_discounts_enabled";
  Flag["ASSET_PICKER_2"] = "asset_picker_2";
  Flag["CMS_HOLDOUT_GROUP_Q3_2021"] = "cms_holdout_group_q3_2021";
  Flag["ASSET_UPLOADER"] = "asset_uploader";
  Flag["ASSET_UPLOADER_REFACTOR"] = "asset_uploader_refactor";
  Flag["LARGE_CARD_UPLOADER"] = "large_card_uploader";
  Flag["DELETION_IN_ASSET_PICKER"] = "deletion_in_asset_picker";
  Flag["MULTI_SELECT_ASSET_PICKER"] = "multi_select_in_asset-picker";
  Flag["DELETION_V1_IN_ASSET_PICKER"] = "deletion_v1_in_asset_picker";
  Flag["IMAGE_BLOCK_ASSET_UPLOADER"] = "image_block_asset_uploader";
  Flag["CAMPAIGNS_IMPORT_COLOR_PALETTE"] = "campaigns_import_color_palette";
  Flag["ASSET_BACKGROUND_UPLOADING"] = "asset_background_uploading";
  Flag["LOGO_UPLOAD_TAGS"] = "logo_upload_tags";
  Flag["VIDEO_UPLOADER"] = "video_uploader";
  Flag["VIDEO_BLOCK_NATIVE_VIDEO"] = "video_block_native_video";
  Flag["EMBED_BLOCK_CUSTOM_THUMBNAIL"] = "embed_block_custom_thumbnail";
  Flag["VIDEO_BLOCK_RESTRICTED_VIDEO_PROVIDERS"] = "video_block_restricted_video_providers";
  Flag["COMMERCE_ORDERS_EXPORT_ASYNC_TEST_ENABLED"] = "commerce_orders_export_async_test_enabled";
  Flag["COMMERCE_AFTERPAY"] = "commerce_afterpay";
  Flag["COMMERCE_CLEARPAY"] = "commerce_clearpay";
  Flag["COMMERCE_AFTERPAY_TOGGLE"] = "commerce_afterpay_toggle";
  Flag["COMMERCE_TAX_EXTENSION_TESTER"] = "commerce_tax_extension_tester";
  Flag["COMMERCE_PDP_SURVEY_MODAL"] = "commerce_pdp_survey_modal";
  Flag["COMMERCE_PDP_LAYOUTS"] = "commerce_pdp_layouts";
  Flag["COMMERCE_PDP_LAYOUTS_GA"] = "commerce_pdp_layouts_ga";
  Flag["COMMERCE_PRODUCT_SERVICE_CATEGORY_DELETION"] = "category-delete-product-service-enabled";
  Flag["COMMERCE_NEXT_ORDER_NUMBER_MIGRATION"] = "commerce_next_order_number_migration";
  Flag["COMMERCE_ORDERS_ELASTICSEARCH_MIGRATION"] = "commerce_orders_elasticsearch_migration";
  Flag["SEVEN_ONE_MENU_OVERLAY_ANIMATIONS"] = "seven_one_menu_overlay_animations";
  Flag["COMMERCE_PDP_EDIT_MODE"] = "commerce_pdp_edit_mode";
  Flag["CAMPAIGNS_NEW_SUBSCRIBER_SEARCH"] = "campaigns_new_subscriber_search";
  Flag["CAMPAIGNS_EMAIL_REUSE_TEMPLATE_FLOW"] = "campaigns_email_reuse_template_flow";
  Flag["CAMPAIGNS_NEW_TEMPLATE_PICKER"] = "campaigns_new_template_picker";
  Flag["CAMPAIGNS_USER_TEMPLATES_IN_SIDEBAR"] = "campaigns_user_templates_in_sidebar";
  Flag["CAMPAIGNS_NEWSLETTER_BLOCK_V3"] = "campaigns_newsletter_block_v3";
  Flag["CAMPAIGNS_HIDE_DELETED_AUTOMATIONS_PANEL"] = "campaigns_hide_deleted_automations_panel";
  Flag["CAMPAIGNS_SHOW_APPLY_WEBSITE_STYLES_BUTTON"] = "campaigns_show_apply_website_styles_button";
  Flag["CAMPAIGNS_ACCORDION_RECIPIENT_PANEL"] = "campaigns_accordion_recipient_panel";
  Flag["CAMPAIGNS_ALT_TEXT"] = "campaigns_alt_text";
  Flag["PAGE_INTERACTIONS_IMPROVEMENTS"] = "page_interactions_improvements";
  Flag["BLOCK_EDITORS_P0"] = "block_editors_p0";
  Flag["BLOCK_EDITORS_P1"] = "block_editors_p1";
  Flag["BLOCK_EDITORS_P2"] = "block_editors_p2";
  Flag["BLOCK_EDITORS_ENABLE_ROSETTA_CODE_FIELD"] = "block_editors_enable_rosetta_code_field";
  Flag["UNDO_REDO_71"] = "undo_redo_71";
  Flag["UNDO_REDO_ITEM_PAGES_71"] = "undo_redo_item_pages_71";
  Flag["TOCK_BLOCK"] = "tock_block";
  Flag["FLUID_ENGINE"] = "fluid_engine";
  Flag["FLUID_ENGINE_IMAGE_OVERLAY"] = "fluid_engine_image_overlay";
  Flag["FLUID_ENGINE_CONTRACTORS"] = "fluid_engine_contractors";
  Flag["FLUID_ENGINE_MOCK_DATA"] = "fluid_engine_mock_data";
  Flag["FLUID_ENGINE_FORMAT_SETTINGS"] = "fluid_engine_format_settings";
  Flag["FLUID_ENGINE_STRETCHED_BUTTONS"] = "fluid_engine_stretched_buttons";
  Flag["FLUID_ENGINE_SHOW_GRID_TOGGLE"] = "fluid_engine_show_grid_toggle";
  Flag["FLUID_ENGINE_MIGRATE_UI"] = "fluid_engine_migrate_ui";
  Flag["FLUID_ENGINE_CLEAN_UP_GRID"] = "fluid_engine_clean_up_grid";
  Flag["FLUID_ENGINE_CLEAN_UP_GRID_BEFORE_DRAG_AND_KEYBOARD"] = "fluid_engine_clean_up_grid_before_drag_and_keyboard";
  Flag["FLUID_ENGINE_UPDATE_MOBILE_BUTTON"] = "fluid_engine_update_mobile_button";
  Flag["FLUID_ENGINE_SECTION_ANNOTATION_PANEL"] = "fluid_engine_section_annotation_panel";
  Flag["FLUID_ENGINE_MOBILE_REORDERING"] = "fluid_engine_mobile_reordering";
  Flag["FLUID_ENGINE_FULL_BLEED"] = "fluid_engine_full_bleed";
  Flag["FLUID_ENGINE_ONBOARDING_WIZARD"] = "fluid_engine_onboarding_wizard";
  Flag["LAYOUT_ENGINE_CATALOGS"] = "layout_engine_catalogs";
  Flag["FLUID_ENGINE_CATALOGS"] = "fluid_engine_catalogs";
  Flag["BLOCK_BACKGROUNDS"] = "block_backgrounds";
  Flag["BLOCK_QUICK_ACTIONS"] = "block_quick_actions";
  Flag["BUTTON_BLOCK_QUICK_ACTIONS"] = "button_block_quick_actions";
  Flag["BUTTONS_UPDATE"] = "buttons_update";
  Flag["PRODUCT_COMPOSER_FEEDBACK_FORM_ON_SAVE"] = "product_composer_feedback_form_on_save";
  Flag["PROFILES_API"] = "profiles_api";
  Flag["COMMERCE_ADD_TO_CART_RATE_LIMITING"] = "commerce_add_to_cart_rate_limiting";
  Flag["ASSET_UPLOAD_TO_MEDIA_UPLOAD_SERVICE"] = "asset_upload_to_media_upload_service";
  Flag["SEVEN_ONE_DEFER_LAYOUT_ENGINE"] = "seven_one_defer_layout_engine";
  Flag["SEVEN_ONE_CACHE_CATALOG_PREVIEW"] = "seven_one_cache_catalog_preview";
  Flag["SEVEN_ONE_REDUCE_CATALOG_REQUESTS"] = "seven_one_reduce_catalog_requests";
  Flag["COMMERCE_FACEBOOK_PANEL_ONSITE_SELLING"] = "commerce_facebook_panel_onsite_selling";
  Flag["COMMERCE_REDUCE_CART_CALCULATIONS"] = "commerce_reduce_cart_calculations";
  Flag["CAMPAIGNS_NEW_BILLING_CARDS"] = "campaigns_new_billing_cards";
  Flag["COMMERCE_PDP_EDIT_MODE_V2"] = "commerce_pdp_edit_mode_v2";
  Flag["OMIT_TWEAKENGINE_TWEAKVALUES"] = "omit_tweakengine_tweakvalues";
  Flag["COMMERCE_DEMO_PRODUCTS_MODAL_FORCE_ENABLED"] = "commerce_demo_products_force_enabled";
  Flag["BLOCK_SELECTOR_REDESIGN"] = "block_selector_redesign";
  Flag["COMMERCE_PDP_LAYOUT_CATALOG"] = "commerce_pdp_layout_catalog";
  Flag["COMMERCE_DEMO_PRODUCTS_MODAL"] = "commerce_demo_products_modal";
  Flag["CAMPAIGNS_ATTACHED_TO_SCHEDULING"] = "campaigns_attached_to_scheduling";
  Flag["CAMPAIGNS_STARTUP_CHECKLIST"] = "startup_checklist";
  Flag["CAMPAIGNS_AUDIENCE_CARD"] = "campaigns_audience_card";
  Flag["CAMPAIGNS_GLOBAL_UNIVERSAL_CHECKOUT"] = "campaigns_global_uc";
  Flag["CAMPAIGNS_GLOBAL_UNIVERSAL_CHECKOUT_AB"] = "campaigns_global_uc_ab";
  Flag["CAMPAIGNS_BANNER_REDUCE_SIZE"] = "campaigns_banner_reduce_size";
  Flag["CAMAPIGNS_INTENT_CATEGORY_CARDS"] = "campaigns_intent_category_cards";
  Flag["CAMAPIGNS_INTENT_CATEGORY_CARDS_AB"] = "campaigns_intent_category_cards_ab";
  Flag["UAS_SWAGGER_SITE_USER_ACCOUNT_CLIENT"] = "uas_swagger_site_user_account_client";
  Flag["DOMAINS_PERMISSIONS_PANEL"] = "domains_permissions_panel";
  Flag["UAS_SWAGGER_SESSION_CLIENT"] = "uas_swagger_session_client";
  Flag["COMMERCE_PDP_LAYOUTS_FORCE_ENABLED"] = "commerce_pdp_layouts_force_enabled";
  Flag["UAS_SWAGGER_UNAUTHENTICATED_SESSION_CLIENT"] = "uas_swagger_unauthenticated_session_client";
  Flag["DIGITAL_DOWNLOAD_LINK_EXPIRATION_RESUBMISSION"] = "digital_download_link_expiration_resubmission";
  Flag["UAS_SWAGGER_TOKEN_CLIENT"] = "uas_swagger_token_client";
  Flag["MEMBER_AREAS_ANNUAL_SUBSCRIPTIONS"] = "member_areas_annual_subscriptions";
  Flag["COMMERCE_ONBOARDING_TOOLS_SCREEN_TEST"] = "commerce_onboarding_tools_screen_test";
  Flag["STYLING_SATISFACTION_SURVEY"] = "styling_satisfaction_survey";
  Flag["COMMERCE_AFTERPAY_TOGGLE_INELIGIBLE"] = "commerce_afterpay_toggle_ineligible";
  Flag["COMMERCE_PRINT_ON_DEMAND_PANEL"] = "commerce_print_on_demand_panel";
  Flag["COMMERCE_AFTERPAY_PDP"] = "commerce_afterpay_pdp";
  Flag["COMMERCE_PRODUCT_BRANCHING"] = "commerce_product_branching";
  Flag["COMMERCE_PRODUCT_REVIEWS_TEST"] = "commerce_product_reviews_test";
  Flag["COMMERCE_SITE_VISITOR_METRICS"] = "commerce_site_visitor_metrics";
  Flag["COMMERCE_PRODUCT_REVIEWS_SITE_VISITOR_ASSIGNMENT"] = "commerce_product_reviews_site_visitor_assignment";
  Flag["COMMERCE_PRODUCT_REVIEWS_IMPORT_KILL_SWITCH"] = "commerce_product_reviews_import_kill_switch";
  Flag["COMMERCE_FTS_REVIEW_SURVEY"] = "commerce_fts_review_survey";
  Flag["SEVEN_ONE_GDPR_OPT_OUT_PANEL"] = "seven_one_gdpr_opt_out_panel";
  Flag["RTE_TOOLBAR_RELEASE"] = "rte_toolbar_release";
  Flag["SEVEN_ONE_RTE_FONT_MAPPING"] = "seven_one_rte_font_mapping";
  Flag["SEVEN_ONE_RTE_TEXT_COLOR_MAPPING"] = "seven_one_rte_text_color_mapping";
  Flag["SEVEN_ONE_RTE_TEXT_COLOR_MAPPING_CUSTOM"] = "seven_one_rte_text_color_mapping_custom";
  Flag["SEVEN_ONE_RTE_TEXT_COLOR_MAPPING_CLEAR"] = "seven_one_rte_text_color_mapping_clear";
  Flag["SEVEN_ONE_BLOCK_FIELD_TOOLBAR"] = "seven_one_block_field_toolbar";
  Flag["SEVEN_ONE_INLINE_EDITABLE_QUOTE_BLOCK"] = "seven_one_inline_editable_quote_block";
  Flag["SEVEN_ONE_INLINE_EDITABLE_BUTTON_BLOCK"] = "seven_one_inline_editable_button_block";
  Flag["SEVEN_ONE_INLINE_EDITABLE_IMAGE_BUTTON"] = "seven_one_inline_editable_image_button";
  Flag["SEVEN_ONE_INLINE_EDITABLE_NEWSLETTER_BLOCK"] = "seven_one_inline_editable_newsletter_block";
  Flag["REDUCE_GENERAL_SEARCH_API_TRAFFIC"] = "reduce_general_search_api_traffic";
  Flag["COMMERCE_PRODUCT_COMPOSER_AB_TEST_ALL_USERS"] = "commerce_product_composer_ab_test_all_users";
  Flag["COMMERCE_DECREASE_CART_REFRESHES"] = "commerce_decrease_cart_refreshes";
  Flag["I18N_BETA_LOCALES"] = "i18n_beta_locales";
  Flag["CUSTOMER_ACCOUNT_CREATION_RECAPTCHA"] = "customer_account_creation_recaptcha";
  Flag["ORDERS_PANEL_CUSTOMER_NOTIFICATIONS_FEATURE_AB_TEST"] = "orders_panel_customer_notifications_feature_ab_test";
  Flag["CUSTOMER_NOTIFICATIONS_EDIT_BUTTON_AB_TEST"] = "customer_notifications_edit_button_ab_test";
  Flag["COMMERCE_DASHBOARD"] = "commerce_dashboard";
  Flag["MEMBER_AREAS_INSTALLMENTS"] = "member_areas_installments";
  Flag["TRANSACTIONAL_EMAILS_BLOCKED_FOR_WEBSITE"] = "transactional_emails_blocked_for_website";
  Flag["MEMBER_AREAS_SCHEDULE_INTERVIEW"] = "member_areas_schedule_interview";
  Flag["MEMBER_AREAS_PRICING_OPTIONS"] = "member_areas_pricing_options";
  Flag["COMMERCE_ETSY_PRODUCT_IMPORT"] = "commerce_etsy_product_import";
  Flag["ACUITY_CONTRIBUTOR_PERMISSIONS"] = "acuity_contributor_permissions";
  Flag["MEMBER_AREAS_CHECKLIST"] = "member_areas_checklist";
  Flag["SUPPORTS_VERSIONED_TEMPLATE_ASSETS"] = "supports_versioned_template_assets";
  Flag["USE_GCS_ASSET_URLS_IN_CONFIG"] = "use_gcs_asset_urls_in_config";
  Flag["COMMERCE_DASHBOARD_PART_2"] = "commerce_dashboard_part_2";
  Flag["ADD_PRODUCTS_FOR_SALE_TEST"] = "add_products_for_sale_test";
  Flag["ETSY_PRODUCT_ONBOARDING"] = "etsy_product_onboarding";
  Flag["DOMAINS_NAMESERVERS_WITH_DNSSEC"] = "domains_nameservers_with_dnssec";
  Flag["UI_TOOLKIT_SCRIPTS"] = "ui_toolkit_scripts";
  Flag["EXTENSIONS_PANEL_V3"] = "extensions_panel_v3";
  Flag["SEVEN_ONE_SECTION_AUTO_LAYOUT_TUTORIALS"] = "seven_one_section_auto_layout_tutorials";
  Flag["PRODUCT_COMPOSER_ORCHESTRATOR"] = "product_composer_orchestrator";
  Flag["COMMERCE_PAYMENT_SURVEY"] = "commerce_payment_survey";
  Flag["MEMBER_AREAS_PREVIEW"] = "member_areas_preview";
  Flag["MEMBER_AREAS_USE_SUBSCRIBABLE_ID_MIGRATED"] = "member_areas_use_subscribable_id_migrated";
  Flag["MEMBER_AREAS_CHECKLIST_V2"] = "member_areas_checklist_v2";
  Flag["ENFORCE_NEWSLETTER_BLOCK_V3_RECAPTCHA"] = "enforce_newsletter_block_v3_recaptcha";
  Flag["PAYMENT_SETTINGS_FULL_SCREEN"] = "payment_settings_full_screen";
  Flag["MEMBER_AREAS_BILLING_STATE_MIGRATION"] = "member_areas_billing_state_migration";
  Flag["COMMERCE_LOCAL_PICKUP"] = "commerce_local_pickup";
  Flag["COMMERCE_DASHBOARD_POST_SETUP_FORCED"] = "commerce_dashboard_post_setup_forced";
  Flag["COMMERCE_DASHBOARD_RUSH_PACKAGE"] = "commerce_dashboard_rush_package";
  Flag["GOOGLE_ANALYTICS_4_GTAG_JS"] = "google_analytics_4_gtag_js";
  Flag["COMMERCE_PRODUCT_REVIEW_ETSY_IMPORT"] = "commerce_product_review_etsy_import";
  Flag["COMMERCE_PRODUCT_REVIEW_ETSY_IMPORT_FORCED"] = "commerce_product_review_etsy_import_forced";
  Flag["COMMERCE_PRODUCT_REVIEW_ETSY_IMPORT_GA"] = "commerce_product_review_import_ga";
  Flag["COMMERCE_NATIVE_PRODUCT_REVIEWS"] = "commerce_native_product_reviews";
  Flag["EMAIL_SETTINGS_FROM_CUSTOMER_COMMUNICATION_HUB_ENABLED"] = "email_settings_from_customer_communication_hub_enabled";
  Flag["COMMERCE_CLASSES"] = "commerce_classes";
  Flag["COMMERCE_VOD_ONBOARDING"] = "commerce_vod_onboarding";
  Flag["COMMERCE_CLASSES_ASSET_UPLOADER"] = "commerce_classes_asset_uploader";
  Flag["COMMERCE_VOD_RELATED_VIDEOS"] = "commerce_vod_related_videos";
  Flag["SEVEN_ONE_HEADER_EFFECTS"] = "seven_one_header_effects";
  Flag["COMMERCE_USE_UPDATE_CART_IN_CART_PERSISTENCE_SERVICE"] = "commerce_use_update_cart_in_cart_persistence_service";
  Flag["SEVEN_ONE_SECTION_GENERATIVE_BACKGROUNDS"] = "seven_one_section_generative_backgrounds";
  Flag["COMMERCE_SHIPPING_LABELS"] = "commerce_shipping_labels";
  Flag["CAMPAIGNS_BLOG_PRODUCT_IMAGE_EDITOR"] = "campaigns_blog_product_image_editor";
  Flag["CAMPAIGNS_SECTION_REORDER_ARROWS"] = "campaigns_section_reorder_arrows";
  Flag["CAMPAIGNS_THUMBNAIL_LAYOUT"] = "campaigns_thumbnail_layout";
  Flag["CAMPAIGNS_STYLE_AB_TEST"] = "campaigns_style_tab_ab_test";
  Flag["CAMPAIGNS_CONTENT_EDITING_SURVEY"] = "campaigns_content_editing_survey";
  Flag["COMMERCE_SHADOW_WRITE_TO_CART_PERSISTENCE_SERVICE"] = "commerce_shadow_write_to_cart_persistence_service";
  Flag["PEOPLE_HUB_PAYWALL_PROCESSOR"] = "people_hub_paywall_processor";
  Flag["DISCOUNTS_API"] = "discounts_api";
  Flag["COMMERCE_READ_CART_FROM_CART_PERSISTENCE_SERVICE"] = "commerce_read_cart_from_cart_persistence_service";
  Flag["SCHEDULING_BLOCK_SCHEMA_EDITOR"] = "scheduling_block_schema_editor";
  Flag["COMMERCE_CHECKOUT_MARKETING_OPT_IN"] = "commerce_checkout_marketing_opt_in";
  Flag["MEMBER_AREAS_DEFERRED_DOWNGRADES"] = "member_areas_deferred_downgrades";
  Flag["BACKGROUND_ART_ONBOARDING"] = "background_art_onboarding";
  Flag["MEMBER_AREAS_SPANISH_INTERVIEWS"] = "member_areas_spanish_interviews";
  Flag["CAMPAIGNS_IFRAME_FRONTEND_ROUTING_UPDATE"] = "campaigns_iframe_frontend_routing_update";
  Flag["PRODUCT_COMPOSER_REDESIGN"] = "product_composer_redesign";
  Flag["MEMBER_AREAS_ENABLE_ON_MEMBERSHIPS_INTERSTITIAL"] = "member_areas_enable_on_memberships_interstitial";
  Flag["COMMERCE_ORDERS_PANEL_ROSETTA"] = "commerce_orders_panel_rosetta";
  Flag["COMMERCE_ORDERS_PANEL_ORDER_DETAILS_ROSETTA"] = "commerce_orders_panel_order_details_rosetta";
  Flag["COMMERCE_ETSY_SHIPPING_IMPORT"] = "commerce_etsy_shipping_import";
  Flag["COMMERCE_KLARNA"] = "commerce_klarna";
  Flag["COMMERCE_KLARNA_AVAILABILITY_BYPASS"] = "commerce_klarna_availability_bypass";
  Flag["BC_CONTENTBROWSER_UAU"] = "bc_contentbrowser_uau";
  Flag["SCHEDULING_ATTACH_PRODUCTS"] = "scheduling_attach_products";
  Flag["MEMBER_AREAS_GRACE_PERIOD_DEACTIVATION"] = "member_areas_grace_period_deactivation";
  Flag["MEMBER_AREAS_PRICING_OPTIONS_DISCOUNTS"] = "member_areas_pricing_options_discounts";
  Flag["COMMERCE_PRODUCT_REVIEWS_MERCHANT_EMAIL"] = "COMMERCE_PRODUCT_REVIEWS_MERCHANT_EMAIL";
  Flag["COMMERCE_PUBLIC_MERCHANDISING_API"] = "COMMERCE_PUBLIC_MERCHANDISING_API";
  Flag["COMMERCE_MULTIPLE_PRODUCT_REVIEWS"] = "commerce_multiple_product_reviews";
  Flag["COMMERCE_SHIPPING_LABEL_PURCHASING"] = "commerce_shipping_label_purchasing";
  Flag["COMMERCE_SHIPPING_LABEL_PURCHASING_DISABLED"] = "commerce_shipping_label_purchasing_disabled";
  Flag["USER_ACCOUNT_LOGIN"] = "user_account_login";
  Flag["DISPLAY_VIDEO_HOSTING_LIMITS"] = "display_video_hosting_limits";
  Flag["BC_PSS_SHOWHIDE_FIELD"] = "bc_pss_showhide_field";
  Flag["BC_PSS_ALIGNMENT_FIELDS"] = "bc_pss_alignment_fields";
  Flag["COMMERCE_PAYMENTS_PANEL_LOGOS"] = "commerce_payments_panel_logos";
  Flag["SUMMARY_BLOCK_VIDEO_COLLECTIONS"] = "summary_block_video_collections";
  Flag["MARQUEE_BLOCK"] = "marquee_block";
  Flag["FORCE_COMMERCE_ONBOARDING"] = "force_commerce_onboarding";
  Flag["COMMERCE_PRODUCT_SPECIFIC_REVIEWS"] = "commerce_product_specific_reviews";
  Flag["I18N_WEGLOT_MULTILINGUAL"] = "i18n_weglot_multilingual";
  Flag["I18N_WEGLOT_PROD_OVERRIDE"] = "i18n_weglot_prod_override";
  Flag["CUSTOM_BUTTON_SHAPES"] = "custom_button_shapes";
  Flag["EDITABLE_PROJECT_TITLE"] = "editable_project_title";
  Flag["COMMERCE_SUBSCRIPTIONS_MULTIMODAL_CHECKOUT"] = "commerce_subscriptions_multimodal_checkout";
  Flag["COMMERCE_PRODUCT_REVIEWS_DASHBOARD"] = "commerce_product_reviews_dashboard";
  Flag["RECURRING_MEMBERSHIPS_ASYNC_CANCELLATION"] = "recurring_memberships_async_cancellation";
  Flag["CRM_SHOW_SUBSCRIBER_IMPORT_IN_PROFILES"] = "crm_show_subscriber_import_in_profiles";
  Flag["ASSET_PICKER_IN_PRODUCT_COMPOSER"] = "asset_picker_in_product_composer";
  Flag["FORCE_COMMERCE_NPS_SURVEY"] = "force_commerce_nps_survey";
  Flag["CAMPAIGNS_ROI_ANALYTICS_CARD"] = "campaigns_roi_analytics_card";
  Flag["CAMPAIGNS_ASSEST_PICKER"] = "campaigns_asset_picker";
  Flag["SEND_LOCAL_PICKUP_READY"] = "send_local_pickup_ready_email";
  Flag["I18N_NFD_BETA_LOCALES"] = "i18n_nfd_beta_locales";
  Flag["MEMBER_AREAS_PRICING_SELECTOR"] = "member_areas_pricing_selector";
  Flag["SQUARESPACE_PAYMENTS_ALPHA"] = "squarespace_payments_alpha";
  Flag["MERCHANDISING_VARIANT_SELECTOR_FIX"] = "merchandising_variant_selector_fix";
  Flag["MEMBER_AREAS_DPC"] = "member_areas_dpc";
  Flag["COMMERCE_PRODUCT_REVIEWS_REQUEST_PAGE_KILL_SWITCH"] = "commerce_product_reviews_request_page_kill_switch";
  Flag["COMMERCE_PRODUCT_REVIEWS_PDP_RENDERING_KILL_SWITCH"] = "commerce_product_reviews_rendering_pdp_kill_switch";
  Flag["COMMERCE_VISITOR_CLDR_CURRENCY_FORMATTER"] = "commerce_visitor_cldr_currency_formatter";
  Flag["SEVEN_ONE_MIGRATION"] = "seven_one_migration";
  Flag["RTE_SCALED_TEXT"] = "rte_scaled_text";
  Flag["PDP_LINKS_IN_CUSTOMER_ACCOUNTS_ORDER_DETAILS"] = "PDP_links_in_customer_accounts_order_details";
  Flag["BACKGROUND_ART_2022_1"] = "bg_art_presets_2022_1";
  Flag["ASSET_DROP_IN_IMAGE_BLOCKS"] = "asset_drop_in_image_blocks";
  Flag["CSS_VAR_COLOR_THEMES"] = "css_var_color_themes";
  Flag["MARKETING_LANDING_PAGE"] = "marketing_landing_page";
})(Flag || (Flag = {}));

var _default = Flag;
exports["default"] = _default;
module.exports = exports.default;