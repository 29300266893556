import get from 'lodash/get';

import { locales as LocaleList, Locale, SupportedTranslationLocale } from '@sqs/i18n-locale-list';
import { LocaleResolver } from '@sqs/i18n-ui';

// tslint:disable-next-line:variable-name
let _memberLocaleResolver: LocaleResolver;
// tslint:disable-next-line:variable-name
let _visitorLocaleResolver: LocaleResolver;

export const getBaseWebpackLocale = () => {
  return __TRANSLATION_LOCALE__ as SupportedTranslationLocale;
};

// Should be one of 'en', 'es', 'pt', 'it', 'fr', 'de'
const getBuildLanguageId = () => {
  return LocaleResolver.parseLocale(getBaseWebpackLocale()).language();
};

// Filter that a locale is preceded by `${buildLanguageId}-`
// i.e. 'en-', 'es-', etc.
export const filterByLanguageId = (localeName: string) => {
  return localeName.indexOf(`${getBuildLanguageId()}-`) === 0;
};

// Filter the locale lists to the ones relevant for the base language of this webpack build
export const getFilteredMemberLocaleList = () => {
  return LocaleList.member.enabled.filter(filterByLanguageId);
};

export const getFilteredVisitorLocaleList = () => {
  return LocaleList.visitor.enabled.filter(filterByLanguageId);
};

/**
 * The defaults are set to the baseWebpackLocale (so in Spanish, `es-ES` is the default formatting
 * locale if resolution fails). I only say this because is NOT `en-US` everywhere.
 *
 * These are functions for testing purposes. In practice you wouldn't supply your own resolver.
 */
export const getMemberLocaleResolver = () => {
  if (!_memberLocaleResolver) {
    _memberLocaleResolver = new LocaleResolver(getFilteredMemberLocaleList(), getBaseWebpackLocale());
  }
  return _memberLocaleResolver;
};

export const getVisitorLocaleResolver = () => {
  if (!_visitorLocaleResolver) {
    _visitorLocaleResolver = new LocaleResolver(getFilteredVisitorLocaleList(), getBaseWebpackLocale());
  }
  return _visitorLocaleResolver;
};

// AFAIK which resolver you use here doesn't matter;
// our core base translation locales are embedded in both lists.
const defaultLocale = getMemberLocaleResolver().resolveLocale(getBaseWebpackLocale()).languageRegion as SupportedTranslationLocale;

/**
 * This state exists because checkout maintains its own context variable, and
 * as a result relies on this state in order to have interoperability with
 * things that expect a Squarespace CONTEXT:
 * /universal/src/apps/Commerce/CheckoutPage/bootstrap.js#101-102
 *
 * AFAICT this is the only reason why we can't grab this straight off of the
 * window.static.CONTEXT :(
 *
 * Ideally we remove all notion of this state once we're able and just grab
 * things off of the context, if possible. This likely means that checkout must
 * persist their own version of this file.
 */
// tslint:disable-next-line:variable-name
const _locales = {
  memberLocale: defaultLocale,
  websiteLocale: defaultLocale,
};

/**
 * Returns the current resolved website locale
 * This state is on the module-level and intended to be shared across bundles
 *
 * @function getResolvedWebsiteLocale
 * @return {string} websiteLocale Current resolved website locale
 */
export function getResolvedWebsiteLocale(): Locale {
  return _locales.websiteLocale;
}

/**
 * Note:
 * These set() functions shouldn't return a value. It's super confusing.
 */

/**
 * Returns the current resolved member locale
 * This state is on the module-level and intented to be shared across bundles
 *
 * @function getResolvedMemberLocale
 * @return {string} memberLocale Current resolved member locale
 */
export function getResolvedMemberLocale(): Locale {
  return _locales.memberLocale;
}

/**
 * Sets the current the resolved website locale.
 */
export function setResolvedWebsiteLocale(locale: Locale): SupportedTranslationLocale {
  if (!locale) {
    locale = getBaseWebpackLocale();
  }
  _locales.websiteLocale = getVisitorLocaleResolver().resolveLocale(locale).languageRegion as SupportedTranslationLocale;
  return _locales.websiteLocale;
}

/**
 * Sets the current the resolved member locale.
 *
 * These shouldn't return a value. It's super confusing.
 */
export function setResolvedMemberLocale(locale: Locale): SupportedTranslationLocale {
  if (!locale) {
    locale = getBaseWebpackLocale();
  }
  _locales.memberLocale = getMemberLocaleResolver().resolveLocale(locale).languageRegion as SupportedTranslationLocale;
  return _locales.memberLocale;
}

export function getWebsiteLocaleFromContext() {
  return get(window.Static, ['SQUARESPACE_CONTEXT', 'website', 'language']);
}

/**
 * Sets the current the resolved website locale from the SQUARESPACE_CONTEXT.
 * Falls back to the baseWebpackLocale if the locale does not exist on CONTEXT.
 */
export function setResolvedWebsiteLocaleFromContext(): Locale {
  const locale = getWebsiteLocaleFromContext();

  if (!locale) {
    if (__DEV__) {
      console.error(
        `Unable to resolve website locale from SQUARESPACE_CONTEXT. Falling back to ${getBaseWebpackLocale()}`
      );
    }
    return setResolvedWebsiteLocale(getBaseWebpackLocale());
  }

  return setResolvedWebsiteLocale(locale);
}

/**
 * Sets the current the resolved member locale from the SQUARESPACE_CONTEXT.
 * Falls back to the baseWebpackLocale if the locale does not exist on CONTEXT.
 */
export function setResolvedMemberLocaleFromContext(): SupportedTranslationLocale {
  const locale = get(
    window.Static,
    ['SQUARESPACE_CONTEXT', 'authenticatedAccount', 'preferredLocale']
  );

  if (!locale) {
    if (__DEV__) {
      console.error(
        `Unable to resolve member locale from SQUARESPACE_CONTEXT. Falling back to ${getBaseWebpackLocale()}`
      );
    }
    return setResolvedMemberLocale(getBaseWebpackLocale());
  }

  return setResolvedMemberLocale(locale);
}

export const getResolvedWebsiteLanguage = () => {
  const locale = getResolvedWebsiteLocale();
  return LocaleResolver.parseLocale(locale).language();
};

export const getResolvedMemberLanguage = () => {
  const locale = getResolvedMemberLocale();
  return LocaleResolver.parseLocale(locale).language();
};

/*******************************/
/* One-off utility functions **/
/*******************************/

/**
 * @see {https://developers.facebook.com/docs/internationalization}
 * @see {https://i18n-docs.squarespace.net/api/sqs-i18n-formatters/api#normalizelocale}
 * @param {string} locale ab-CD
 * @return {string} ab_CD
 */
export function formatLocaleForFacebook(locale: string): string {
  return locale
    .replace('-', '_')
    .replace('es_419', 'es_LA');
}
