import _typeof from "@babel/runtime/helpers/typeof";
import { StaticPraetorClient } from '@sqs/praetor';
export var getExperimentList = function getExperimentList(listName) {
  var _window$Static, _window$Static$SQUARE;

  return typeof window !== 'undefined' ? ((_window$Static = window.Static) === null || _window$Static === void 0 ? void 0 : (_window$Static$SQUARE = _window$Static.SQUARESPACE_CONTEXT) === null || _window$Static$SQUARE === void 0 ? void 0 : _window$Static$SQUARE[listName]) || [] : [];
};
/**
 * Given a string, we create a SubjectVariant with the experiment list.
 * Otherwise, given a SubjectVariant we just return a new praetor client
 * instance
 *
 * @param {String | Object} config
 */

export var getClient = function getClient(config) {
  return new StaticPraetorClient(_typeof(config) === 'object' ? config : {
    isConfigurationLoaded: true,
    experimentContextList: getExperimentList(config)
  });
};