/**
 * This util is a self executing closure to prevent users from editing `Static.SQUARESPACE_CONTEXT` and
 * obtaining access to unauthorized beta features.
 *
 * Because this util caches data during import time, make sure `Static.SQUARESPACE_CONTEXT` is bootstrapped
 * beforehand.
 */
var MultiProductConfigUtils = function () {
  var _window, _window$Static, _window$Static$SQUARE, _window2, _window2$Static, _window2$Static$SQUAR;

  var _isMultiProductConfig = typeof window !== 'undefined' ? ((_window = window) === null || _window === void 0 ? void 0 : (_window$Static = _window.Static) === null || _window$Static === void 0 ? void 0 : (_window$Static$SQUARE = _window$Static.SQUARESPACE_CONTEXT) === null || _window$Static$SQUARE === void 0 ? void 0 : _window$Static$SQUARE.isMultiProductConfig) === true : false;

  var _isStandaloneScheduling = typeof window !== 'undefined' ? ((_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$Static = _window2.Static) === null || _window2$Static === void 0 ? void 0 : (_window2$Static$SQUAR = _window2$Static.SQUARESPACE_CONTEXT) === null || _window2$Static$SQUAR === void 0 ? void 0 : _window2$Static$SQUAR.isStandaloneScheduling) === true : false;

  return {
    isMultiProductConfig: function isMultiProductConfig() {
      return _isMultiProductConfig;
    },
    isStandaloneScheduling: function isStandaloneScheduling() {
      return _isStandaloneScheduling;
    }
  };
}();

export default MultiProductConfigUtils;