import network from '@sqs/network';
var INTERNAL_API_URL = '/api/labs/internal';
var CIRCLE_API_URL = '/api/labs/circle';
/**
 * This util is a self executing closure to prevent users from editing `Static.SQUARESPACE_CONTEXT` and
 * obtaining access to unauthorized beta features.
 *
 * Because this util caches data during import time, make sure `Static.SQUARESPACE_CONTEXT` is bootstrapped
 * beforehand.
 *
 * Be careful importing modules here because it may affect visitor facing load times.
 */

var BetaFeaturesUtils = function () {
  var _window$Static, _window$Static$SQUARE;

  var enabledBackendFlags = typeof window !== 'undefined' ? ((_window$Static = window.Static) === null || _window$Static === void 0 ? void 0 : (_window$Static$SQUARE = _window$Static.SQUARESPACE_CONTEXT) === null || _window$Static$SQUARE === void 0 ? void 0 : _window$Static$SQUARE.betaFeatureFlags) || [] : [];
  return {
    getInternalLabsFeatures: function getInternalLabsFeatures() {
      return network.get(INTERNAL_API_URL);
    },
    getCircleLabsFeatures: function getCircleLabsFeatures() {
      return network.get(CIRCLE_API_URL);
    },
    saveInternalLabsFeatures: function saveInternalLabsFeatures(features) {
      return network.post(INTERNAL_API_URL, features);
    },
    saveCircleLabsFeatures: function saveCircleLabsFeatures(features) {
      return network.post(CIRCLE_API_URL, features);
    },
    getEnabledBackendFlags: function getEnabledBackendFlags() {
      return enabledBackendFlags;
    },
    isFeatureEnabled: function isFeatureEnabled(feature) {
      return !!feature && enabledBackendFlags.indexOf(feature) !== -1;
    }
  };
}();

export default BetaFeaturesUtils;