import cookieCutter from '@sqs/cookie-cutter';

/**
 * Check a cookie to see if its value is 'true', the string.
 * @param  {String} key Cookie key to check
 * @return {Boolean}    True the document exists and a cookie has the right key.
 */
export function checkCookie(key: string) {
  // cookie cutter doesn't add methods if the document is missing, which is dumb.
  if (!cookieCutter.get) {
    return false;
  }
  return cookieCutter.get(key) === 'true';
}
