import Flag from '@sqs/enums/Flag';
import { getClient } from '../utils/praetor';
import legacyV6Flags from '../clients/legacyV6Flags';
var client = getClient('i18nExperimentList');
var REGION_FORMAT_PREVIEW_TEST_NAME = 'region-format-preview';
var REGION_FORMAT_PREVIEW_DEFAULT_VALUE = 'disabled';
var REGION_FORMAT_PREVIEW_ACTIVE_VALUE = 'enabled';
var FAKE_DOOR_TEST_NAME = 'multilingual-email-fake-door-test';
var FAKE_DOOR_DEFAULT_VALUE = 'disabled';
var FAKE_DOOR_ACTIVE_VALUE = 'enabled';
export var isCommerceVisitorCldrCurrencyFormatterEnabled = function isCommerceVisitorCldrCurrencyFormatterEnabled() {
  return legacyV6Flags.isFeatureEnabled(Flag.COMMERCE_VISITOR_CLDR_CURRENCY_FORMATTER);
};
export var isRegionFormatPreviewEnabled = function isRegionFormatPreviewEnabled() {
  var _client$getABTestVari = client.getABTestVariant(REGION_FORMAT_PREVIEW_TEST_NAME, REGION_FORMAT_PREVIEW_DEFAULT_VALUE),
      variant = _client$getABTestVari.variant;

  return variant === REGION_FORMAT_PREVIEW_ACTIVE_VALUE;
};
export var isMultilingualFakeDoorTestEnabled = function isMultilingualFakeDoorTestEnabled() {
  var _client$getABTestVari2 = client.getABTestVariant(FAKE_DOOR_TEST_NAME, FAKE_DOOR_DEFAULT_VALUE),
      variant = _client$getABTestVari2.variant;

  return variant === FAKE_DOOR_ACTIVE_VALUE;
};